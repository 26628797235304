// extracted by mini-css-extract-plugin
export var root = "PlasmicSection-module--root--jHnqc";
export var root__color_halfDark = "PlasmicSection-module--root__color_halfDark--dRsuR";
export var root__color_dark = "PlasmicSection-module--root__color_dark--wCSoF";
export var content = "PlasmicSection-module--content--fQY7E";
export var titleContainer = "PlasmicSection-module--titleContainer--VA+WA";
export var titleContainer__hasTitle = "PlasmicSection-module--titleContainer__hasTitle--cdoKC";
export var slotTargetTitle = "PlasmicSection-module--slotTargetTitle--TPZrZ";
export var subtitleContainer = "PlasmicSection-module--subtitleContainer--DrPHW";
export var subtitleContainer__hasSubtitle = "PlasmicSection-module--subtitleContainer__hasSubtitle--2S6bD";
export var slotTargetSubtitle = "PlasmicSection-module--slotTargetSubtitle--zk70I";
export var contentContainer = "PlasmicSection-module--contentContainer--LJY9K";
export var contentContainer__hasTitle = "PlasmicSection-module--contentContainer__hasTitle--vxjAX";
export var contentContainer__hasSubtitle = "PlasmicSection-module--contentContainer__hasSubtitle--r4h8Q";
export var contentContainer__hasTitle_hasSubtitle = "PlasmicSection-module--contentContainer__hasTitle_hasSubtitle--fYEpr";