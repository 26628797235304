// extracted by mini-css-extract-plugin
export var root = "PlasmicHeader-module--root--V7gZH";
export var link = "PlasmicHeader-module--link--LYxX6";
export var svg = "PlasmicHeader-module--svg--WhO1u";
export var freeBox = "PlasmicHeader-module--freeBox--30BTc";
export var button___7GRWd = "PlasmicHeader-module--button___7GRWd--LPWUi";
export var svg__ux3G2 = "PlasmicHeader-module--svg__ux3G2--gcrgv";
export var svg__l8Djp = "PlasmicHeader-module--svg__l8Djp--exryA";
export var button__wfobR = "PlasmicHeader-module--button__wfobR--LY6fe";
export var svg__otfjg = "PlasmicHeader-module--svg__otfjg--qusZ2";
export var svg___9XacZ = "PlasmicHeader-module--svg___9XacZ--OaRZ1";