// extracted by mini-css-extract-plugin
export var all = "plasmic_habistack_com-module--all--zdN9N";
export var img = "plasmic_habistack_com-module--img--4cBRj plasmic_habistack_com-module--all--zdN9N";
export var li = "plasmic_habistack_com-module--li--nZoF1 plasmic_habistack_com-module--all--zdN9N";
export var span = "plasmic_habistack_com-module--span--wGQlr plasmic_habistack_com-module--all--zdN9N";
export var input = "plasmic_habistack_com-module--input--s33I+ plasmic_habistack_com-module--all--zdN9N";
export var textarea = "plasmic_habistack_com-module--textarea--aLeoi plasmic_habistack_com-module--all--zdN9N";
export var button = "plasmic_habistack_com-module--button--YHmMk plasmic_habistack_com-module--all--zdN9N";
export var code = "plasmic_habistack_com-module--code--BVyHb plasmic_habistack_com-module--all--zdN9N";
export var pre = "plasmic_habistack_com-module--pre--89wjr plasmic_habistack_com-module--all--zdN9N";
export var p = "plasmic_habistack_com-module--p--24UrG plasmic_habistack_com-module--all--zdN9N";
export var h1 = "plasmic_habistack_com-module--h1--IaThH plasmic_habistack_com-module--all--zdN9N";
export var h2 = "plasmic_habistack_com-module--h2--NyKf4 plasmic_habistack_com-module--all--zdN9N";
export var h3 = "plasmic_habistack_com-module--h3--e7vdU plasmic_habistack_com-module--all--zdN9N";
export var h4 = "plasmic_habistack_com-module--h4--iblNq plasmic_habistack_com-module--all--zdN9N";
export var h5 = "plasmic_habistack_com-module--h5--66+zj plasmic_habistack_com-module--all--zdN9N";
export var h6 = "plasmic_habistack_com-module--h6--Bb6KJ plasmic_habistack_com-module--all--zdN9N";
export var address = "plasmic_habistack_com-module--address--l56vS plasmic_habistack_com-module--all--zdN9N";
export var a = "plasmic_habistack_com-module--a--oPeTp plasmic_habistack_com-module--all--zdN9N";
export var ol = "plasmic_habistack_com-module--ol--nwruR plasmic_habistack_com-module--all--zdN9N";
export var ul = "plasmic_habistack_com-module--ul--LkZCk plasmic_habistack_com-module--all--zdN9N";
export var select = "plasmic_habistack_com-module--select--kmwyB plasmic_habistack_com-module--all--zdN9N";
export var plasmic_default__component_wrapper = "plasmic_habistack_com-module--plasmic_default__component_wrapper--rJc-y";
export var plasmic_default__inline = "plasmic_habistack_com-module--plasmic_default__inline--UUH60";
export var plasmic_page_wrapper = "plasmic_habistack_com-module--plasmic_page_wrapper--9QT7C";
export var root_reset = "plasmic_habistack_com-module--root_reset--AOr6Y";