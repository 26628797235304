// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--NdBda";
export var freeBox__o4MUa = "PlasmicFooter-module--freeBox__o4MUa--Yhd6t";
export var freeBox__yh9U6 = "PlasmicFooter-module--freeBox__yh9U6--KBQ4A";
export var freeBox___9WTvX = "PlasmicFooter-module--freeBox___9WTvX--dmx4f";
export var freeBox___3B7Ie = "PlasmicFooter-module--freeBox___3B7Ie--d3ynY";
export var iconLink = "PlasmicFooter-module--iconLink--c4BMQ";
export var svg__nVuo = "PlasmicFooter-module--svg__nVuo--VwDW0";
export var freeBox___1Gcwe = "PlasmicFooter-module--freeBox___1Gcwe--UWN4M";
export var httpsfathymcomtermsOfServices = "PlasmicFooter-module--httpsfathymcomtermsOfServices--lCWnH";
export var httpsfathymcomprivacyPolicy = "PlasmicFooter-module--httpsfathymcomprivacyPolicy--srdps";
export var httpsfathymcomenterpriseAgreement = "PlasmicFooter-module--httpsfathymcomenterpriseAgreement--yZzCM";
export var freeBox__kj2W = "PlasmicFooter-module--freeBox__kj2W--eNqCs";
export var text__uu6G5 = "PlasmicFooter-module--text__uu6G5--YQg2+";
export var httpswwwlowcodeunitcom = "PlasmicFooter-module--httpswwwlowcodeunitcom--WaulQ";
export var httpswwwiotEnsemblecom = "PlasmicFooter-module--httpswwwiotEnsemblecom--a-cgE";
export var httpswwwhabistackcom = "PlasmicFooter-module--httpswwwhabistackcom--w1YRv";
export var httpswwwproadjectcom = "PlasmicFooter-module--httpswwwproadjectcom--nZ1Rn";
export var freeBox__bVuy = "PlasmicFooter-module--freeBox__bVuy--acOYp";
export var text__aQKqR = "PlasmicFooter-module--text__aQKqR--gfZnT";
export var httpswwwhabistackcomdocs = "PlasmicFooter-module--httpswwwhabistackcomdocs--wH+6h";
export var httpswwwhabistackcomblog = "PlasmicFooter-module--httpswwwhabistackcomblog--QhN9u";
export var mailtosupportfathymcom = "PlasmicFooter-module--mailtosupportfathymcom--N1JEG";
export var freeBox__e8C2 = "PlasmicFooter-module--freeBox__e8C2--D++Ys";
export var text___6NUk = "PlasmicFooter-module--text___6NUk--2-uGc";
export var httpswwwfathymcom = "PlasmicFooter-module--httpswwwfathymcom--pWeB3";
export var freeBox__iwpD = "PlasmicFooter-module--freeBox__iwpD--egb3K";
export var text__wX3Lh = "PlasmicFooter-module--text__wX3Lh--EpK9O";
export var text__cy5Gi = "PlasmicFooter-module--text__cy5Gi--SAIaW";
export var textInput = "PlasmicFooter-module--textInput--Mwnl5";
export var svg__ns2Ob = "PlasmicFooter-module--svg__ns2Ob--Lesso";
export var button = "PlasmicFooter-module--button--79fOp";
export var svg__n38Ec = "PlasmicFooter-module--svg__n38Ec--QjIzX";
export var svg___1ZgSa = "PlasmicFooter-module--svg___1ZgSa--cBNGF";
export var svg__uyZfb = "PlasmicFooter-module--svg__uyZfb--AvDUF";
export var freeBox__znNa = "PlasmicFooter-module--freeBox__znNa--MxJ5E";
export var freeBox__jqjPm = "PlasmicFooter-module--freeBox__jqjPm--pebR9";
export var freeBox___8JHt = "PlasmicFooter-module--freeBox___8JHt--BF0wy";
export var text__wi6PH = "PlasmicFooter-module--text__wi6PH--F2xew";
export var img = "PlasmicFooter-module--img--dZm2M";
export var __wab_imgSpacer = "PlasmicFooter-module--__wab_img-spacer--I+Aug";
export var text__zEbF = "PlasmicFooter-module--text__zEbF--qpKa4";
export var freeBox__q2LUz = "PlasmicFooter-module--freeBox__q2LUz--n9JqS";
export var fathymIt = "PlasmicFooter-module--fathymIt--WLqOB";
export var twitter = "PlasmicFooter-module--twitter--ZBXqN";
export var httpsgithubcomfathym = "PlasmicFooter-module--httpsgithubcomfathym--kvuyz";
export var gitHub = "PlasmicFooter-module--gitHub--vDgci";
export var httpswwwfacebookcomFathymInc = "PlasmicFooter-module--httpswwwfacebookcomFathymInc--HHhQO";
export var facebook = "PlasmicFooter-module--facebook--a-KbE";