// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export function FathymLogoGreensvgIcon(props) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 191.29 50"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"currentColor"}
        opacity={"1"}
        d={
          "M77.65 1.91c-7.68 0-12.45 5-12.45 13.09v3.1h-3.55v6h3.59v17.07h6V24.12h3.59v-6h-3.59V15c0-4.69 2.17-7.07 6.43-7.07h.62v-6zm104.53 17.8a9.26 9.26 0 00-6 1.85 9.43 9.43 0 00-5.94-1.85 9.06 9.06 0 00-4.49 1.08v-.18h-4.31v21.08h6V28.63c0-2 .53-2.9 2.89-2.9 2.52 0 3 .93 3 2.9v13.06h6V28.63c0-2 .48-2.9 2.85-2.9s3 .93 3 2.9v13.06h6V28.05c.12-3.35-2.31-8.34-9-8.34zm-55.95.29a11.15 11.15 0 00-3.09.42v-9.88h-6v31.2h6V28.63c0-2 .86-2.66 3.09-2.66s2.79.7 2.79 2.66v13.06h6V28.63c.02-5.44-3.27-8.63-8.79-8.63zm-40.21-1.9h-6v6l6-.1c3.76 0 4.85 1.86 5.26 3.73-1.1-1-3.16-1.19-4.86-1.19-4.62 0-9.15 2.31-9.15 7.63a7.46 7.46 0 002.19 5.55c1.61 1.56 3.94 2.35 6.93 2.35h.57a9.61 9.61 0 004.48-1.32v.93h6V28.83c.01-6.62-4.37-10.73-11.42-10.73zm5 16.15c0 2.58-2.54 2.69-4.2 2.69-1.83 0-4.2-.08-4.2-2.69s2.55-2.7 4.2-2.7c1.83 0 4.22.08 4.22 2.7zm18.14-16.17v-7.59h-6.02v7.59h-2.38v6.02h2.38v17.59h6.02V24.1h4.31v-6.02h-4.31zm49.49 2.67h-6.38l-4.52 13.28-4.38-13.28h-6.67l8.08 21.26v6.87h5.79v-6.87l8.08-21.26zM3.1 26.58l.06.47C3.47 29.75 4.41 38 13.92 38a9.91 9.91 0 007.77-3.35 9.64 9.64 0 002-7.86 22.18 22.18 0 00-1.52-4.89 17 17 0 01-1.58-7.1c0-2.62.82-8.71 8.38-8.71s8.39 6.09 8.39 8.71a17 17 0 01-1.58 7.1 22.18 22.18 0 00-1.52 4.89 9.6 9.6 0 002 7.86A9.91 9.91 0 0044.03 38c9.51 0 10.45-8.25 10.76-11v-.46c.27-2.4.49-2.4 1.42-2.4v-6.09c-4.38 0-6.89 2.63-7.47 7.82l-.05.45c-.45 3.91-1.2 5.54-4.71 5.54a3.91 3.91 0 01-3.12-1.18 3.6 3.6 0 01-.67-2.92 17.61 17.61 0 011.17-3.63 22.8 22.8 0 002-9.37c0-7.37-4.48-14.8-14.48-14.8s-14.47 7.43-14.47 14.8a22.8 22.8 0 002 9.37 17.61 17.61 0 011.17 3.63 3.6 3.6 0 01-.67 2.92 3.91 3.91 0 01-3.12 1.18c-3.51 0-4.27-1.63-4.71-5.54l-.05-.45c-.58-5.19-3.09-7.82-7.47-7.82v6.09c1.09.04 1.28.04 1.54 2.44z"
        }
      ></path>

      <path
        fill={"currentColor"}
        opacity={"1"}
        d={
          "M25.07 18.07h2.86v4.98h-2.86zm4.98 0h2.86v4.98h-2.86zM54.59 41a15.46 15.46 0 01-10.53 3.8 14.56 14.56 0 01-11.38-5 14.23 14.23 0 01-1.45-2.07l-.92-1.62h-2.66l-.92 1.62a14.23 14.23 0 01-1.45 2.07 14.56 14.56 0 01-11.38 5 15.55 15.55 0 01-10.43-3.7l-3.49 3.79A20.67 20.67 0 0013.92 50a19.68 19.68 0 0015.07-6.56A19.65 19.65 0 0044.06 50c3.18 0 9.24-.68 14.06-5.2z"
        }
      ></path>
    </svg>
  )
}

export default FathymLogoGreensvgIcon
/* prettier-ignore-end */
